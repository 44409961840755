import avtar from "../../assets/images/default-avatar.jpg";
const initialState = {
  profileImage:localStorage.getItem("profileImage")
  ??avtar,
};

const profileReducer = (state = initialState, action) => {
    if (action.type==="UPDATE_PROFILE_IMAGE") {
        return {
          ...state,
          profileImage: action.payload,
        }
      }
      else{return state;}
  };
  
  export default profileReducer;
  